/* eslint-disable max-classes-per-file */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useContext, useState, useEffect } from "react";
import { Page, Layout, FooterHelp, Link, Button, Card, Stack, Heading, Banner } from "@shopify/polaris";

import { useProfile, SubscriptionContext, useFetch, useRedirect } from "./components/utils/adminFrontend";
import "./Home.scss";

import { useIsDebugMode } from "./DebugMode";
import RunTemplateLegacy from "./components/RunTemplateLegacy";

import RecentFiles from "./components/RecentFiles";
import MiniReports from "./components/MiniReports";

const LEGACY_SHOP_ID_LIMIT = 18493;
const FAKE_INFINITY_REPORT_COUNT = 999999999;
const ALWAYS_SHOW_REPORT_COUNT_THRESHOLD = 5;


function ScopeBanner() {
  const [profile] = useProfile();
  const authFetch = useFetch();
  const redirect = useRedirect();


  const [hasLocationScope, setHasLocationScope] = useState(profile?.shop?.scope.match(/read_locations/));

  useEffect(() => {
    if (window?.shopify) {
      window.shopify.scopes.query().then((d) => {
        const appBridgeCheck = d.granted.join(",").match(/read_locations/);
        setHasLocationScope(appBridgeCheck);
      });
    }
    
  }, [setHasLocationScope]);

  const addScope = useCallback(()=> {
    authFetch(`/api/profile/scopes/add`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ scopes: ["read_locations"], redirect_url: '/' }),
    })
      .then((r) => r.json())
      .then(() => {
        const oauthLoginUrl = "/api/login";
        const shopOrigin = profile.shop.shop_origin;

        authFetch(`${oauthLoginUrl}?shop=${shopOrigin}`)
          .then((r) => r.json())
          .then(({ url }) => {
            redirect(url);
          });
      });
  },[authFetch, profile.shop.shop_origin, redirect])
  
  return (
    <>
      {!!profile?.shop?.scope && !hasLocationScope && (
        <>
          <Banner
            status="warning"
            title="Permissions update"
            action={{
              content: "Approve",
              onAction: addScope,
            }}
          >
            <p>
              To provide reports with location data, including store, warehouse and inventory by location information, please click
              the button below. You will be redirected to Shopify to approve the new permissions. Reach out to support for questions
              on this or any other topic!
            </p>
          </Banner>
          <br />
        </>
      )}
    </>
  );
}



function Home() {
  const debugVisible = useIsDebugMode();
  const [profile] = useProfile();
  const subscription = useContext(SubscriptionContext);

  const maxTemplates = subscription.activePlan?.features["max-templates"].value;

  return (
    <div className="home">
      <Page
        fullWidth
        title="Welcome to Xporter!"
        subtitle={
          <>
            Reach out to support using the icon below or visit our{" "}
            <Link url="https://support.moddapps.com/hc/en-us/categories/360005759313-Xporter" external>
              Help Center
            </Link>
            .
          </>
        }
        secondaryActions={[
          {
            content: "Report Library",
            url: "/template_library",
          },
          {
            content: "File Manager",
            url: "/files",
          },
          {
            content: "Account",
            url: "/account",
          },
          ...(debugVisible
            ? [
                {
                  content: "Debug",
                  url: "/debug",
                },
              ]
            : []),
        ]}
      >
        <ScopeBanner/>
        <Layout>
          <Layout.Section>
            <RecentFiles />
            {profile?.shop?.id < LEGACY_SHOP_ID_LIMIT && <RunTemplateLegacy type="shop" />}
          </Layout.Section>
          <Layout.Section secondary>
            <MiniReports
              custom
              head={({ count }) => {
                const templateCount = count || 0;
                const canCreate = templateCount && templateCount < maxTemplates;

                const shouldShowCount =
                  maxTemplates < FAKE_INFINITY_REPORT_COUNT || maxTemplates - templateCount < ALWAYS_SHOW_REPORT_COUNT_THRESHOLD;

                if (count === 0) {
                  return (
                    <Card.Section>
                      <Stack vertical>
                        <Heading>My Custom Reports</Heading>
                        <Button url="/templates" fullWidth>
                          Create your first custom report
                        </Button>
                      </Stack>
                    </Card.Section>
                  );
                }

                if (count > 0) {
                  return (
                    <Card.Section>
                      <Stack>
                        <Stack.Item fill>
                          <Stack vertical spacing="extraTight">
                            <Heading>My Custom Reports</Heading>
                            {shouldShowCount ? (
                              <>
                                {templateCount} of {maxTemplates} used
                                {templateCount >= maxTemplates && (
                                  <>
                                    {" "}
                                    (<Link url="/account">upgrade for more</Link>)
                                  </>
                                )}
                              </>
                            ) : (
                              <>{templateCount} used of unlimited</>
                            )}
                          </Stack>
                        </Stack.Item>
                        {canCreate ? (
                          <Stack.Item>
                            <Button plain url="/templates?return_to=/">
                              New Report
                            </Button>
                          </Stack.Item>
                        ) : null}
                      </Stack>
                    </Card.Section>
                  );
                }

                return null;
              }}
            />
            <MiniReports
              head={() => (
                <Card.Section>
                  <Stack>
                    <Stack.Item>
                      <Heading>Starter Reports</Heading>
                    </Stack.Item>
                    <Stack.Item>Common reports to run or modify</Stack.Item>
                  </Stack>
                </Card.Section>
              )}
              starter
            />
          </Layout.Section>
        </Layout>

        <FooterHelp>
          Xporter - © {new Date().getFullYear()}{" "}
          <Link external url="https://moddapps.com" href="https://moddapps.com">
            Modd Apps
          </Link>
        </FooterHelp>
      </Page>
    </div>
  );
}

export default Home;
