/* eslint-disable import/no-named-as-default */
import React, { useEffect, useState, useCallback, useContext, useMemo } from "react";
import { useParams, useLocation, Prompt } from "react-router-dom";

import {
  Page,
  Card,
  Stack,
  Modal,
  FooterHelp,
  Banner,
  List,
  Button,
  Link,
  SkeletonPage,
  SkeletonBodyText,
  TextField,
  EmptyState,
  Layout,
  Tabs,
  TextStyle,
  Heading,
} from "@shopify/polaris";
import { EditMinor } from "@shopify/polaris-icons";
import ReactJson from "react-json-view";
import Editor from "@monaco-editor/react";

import { useProfile, SubscriptionContext, useRedirect } from "./components/utils/adminFrontend";

import "./TemplateEditor.scss";
import TemplateSaveBar, { useSaveTemplate } from "./components/TemplateSaveBar";
import TemplateBaseDetailsModal from "./components/template/TemplateBaseDetailsModal";
import Xml from "./components/template/Xml";
import TemplateSorting from "./components/template/TemplateSorting";
import TemplatePreSorting from "./components/template/TemplatePreSorting";
import TemplateFiltering from "./components/template/TemplateFiltering";
import TemplateSettingsModal from "./components/template/TemplateSettingsModal";
import TemplateContext, { useReportTemplate } from "./TemplateContext";
import AutoGenModal from "./components/template/AutoGenModal";
import AddFieldCard from "./components/template/AddFieldCard";
import TemplateLimitBanner from "./components/TemplateLimitBanner";
import DuplicateModal from "./components/template/DuplicateModal";
import { useXporterApi } from "./ApiProvider";
import { useDebugModeClick, useIsDebugMode } from "./DebugMode";
import TemplateLayoutGrid from "./components/template/TemplateLayoutGrid";
import AutoGenLine from "./components/template/AutoGenLine";
import StickyCard from "./components/template/StickyCard";
import ActiveFieldList from "./components/template/ActiveFieldList";
import FormatIcon from "./components/template/FormatIcon";
import { FILE_FORMATS } from "./components/template/values";
import useRunTemplate from "./components/useRunTemplate";

const STICK_CARD_TABS = [
  {
    id: "active-fields",
    content: "Columns",
    panelID: "accepts-marketing-content-1",
  },
  {
    id: "active-filters",
    content: "Filters",
    panelID: "active-filters",
  },
  {
    id: "sort-order",
    content: "Sorting",
    panelID: "sort-order",
  },
];

const ACTIVE_FIELDS_TAB = 0;
const ACTIVE_FILTERS_TAB = 1;
const SORT_ORDER_TAB = 2;

const MIN_WIDTH_FOR_SIDE_BY_SIDE = 600;

function useGetTemplateUsage() {
  const [, , content] = useReportTemplate();
  const template = content.current;
  const subscription = useContext(SubscriptionContext);
  const plan = subscription?.plans?.find(({ id }) => id === subscription?.activePlan?.id);

  if (!subscription || !template || !plan?.features) {
    return null;
  }

  const templateUsage = Object.entries(plan.features)
    .flatMap(([featureKey]) => {
      if (plan.features[featureKey].value) {
        return null;
      }

      let data = null;

      switch (featureKey) {
        case "computed-fields": {
          const hasComputed = template.reportFields.find(({ id }) =>
            content.objectFields.find(({ id: objId, type }) => objId === id && type === "computed")
          );
          data = hasComputed ? featureKey : null;
          break;
        }
        case "static-fields": {
          const hasStatic = template.reportFields.find(({ text }) => text);
          data = hasStatic ? featureKey : null;
          break;
        }
        case "liquid-fields": {
          const hasLiquidField = template.reportFields.some(({ liquid }) => liquid);
          const hasLiquidRow = template.reportLiquidRows.length > 0;
          const hasLiquidFilter = template.reportFilters.find(({ id }) => id.match("liquid"));
          data = hasLiquidField || hasLiquidRow || hasLiquidFilter ? featureKey : null;
          break;
        }
        case "metafields": {
          const hasStatic = template.reportFields.find(({ id }) => id.match(/metafields|transactions|risk/));
          data = hasStatic ? featureKey : null;
          break;
        }
        case "join-separators": {
          break;
        }

        case "sorting": {
          const hasSorting = template.reportPostSorting.length ? featureKey : null;
          const hasPostProcessing = template.reportFields.some(({ groupingType }) => groupingType);
          data = hasSorting || hasPostProcessing ? featureKey : null;
          break;
        }

        default:
          break;
      }

      return data;
    })
    .filter((msg) => msg);
  return templateUsage;
}

function useCanSaveTemplate() {
  const [, , content] = useReportTemplate();
  const template = content.current;
  const subscription = useContext(SubscriptionContext);
  const profile = useProfile()[0];

  const csMember = profile.user.watchtower;
  const plan = subscription?.plans?.find(({ id }) => id === subscription?.activePlan?.id);
  const templateUsage = useGetTemplateUsage();

  // perl report isnt a plan feature, so we check ftp permissions instead
  const isPerlReport = Boolean(template.reportEval);
  
  return (
    (templateUsage && templateUsage.length === 0 && !isPerlReport) ||
    (isPerlReport && plan.features.ftp.value) ||
    (isPerlReport && csMember)
  );
}

function StarterTemplateBanner() {
  const [, , content] = useReportTemplate();
  const template = content.current;
  const subscription = useContext(SubscriptionContext);
  const plan = subscription?.plans?.find(({ id }) => id === subscription?.activePlan?.id);
  const templateUsage = useGetTemplateUsage();
  const canStaveTemplate = useCanSaveTemplate();

  if (!subscription || !template || !plan?.features) {
    return null;
  }


  const isPerlReport = template.reportEval;
  if (canStaveTemplate) {
    return null;
  }

  const recomendedPlans = isPerlReport
    ? subscription.plans.filter((sub) => sub.name === "Professional")
    : subscription.plans
        .filter(({ features }) => {
          const a = templateUsage.every((feature) => features[feature].value);
          return a;
        })
        .sort((x, y) => x.displayRank < y.displayRank);

  const url = `/account${
    templateUsage.length === 1
      ? `?highlightFeature=${templateUsage[0]}&featureValue=${recomendedPlans[0].features[templateUsage[0]].value}`
      : ""
  }`;

  return (
    <Banner status="warning" title="Upgrade Required" action={{ content: "Learn More", url }}>
      <p>This template requires the following features</p>
      <br />
      <List type="bullet">
        {templateUsage.map((msg) => (
          <List.Item>{plan.features[msg].display.key}</List.Item>
        ))}
        {isPerlReport ? <List.Item>Custom Reporting</List.Item> : null}
      </List>
      <br />
      <p>In order to save this template you&apos;ll need to upgrade to {`${recomendedPlans[0].name}`}.</p>
    </Banner>
  );
}


function NormalTemplate({ loaded, templateId, debugVisible }) {
  const [current, templateDispatch, content] = useReportTemplate();
  const xporterAPI = useXporterApi();

  const advancedModalActive = content.modals.settings;
  const toggleAdvancedModalActive = useCallback(
    () => templateDispatch({ type: "toggleModal", value: { settings: !advancedModalActive } }),
    [advancedModalActive, templateDispatch]
  );

  const { selectedStickyCardTab } = content;
  const setSelectedStickyCardTab = useCallback(
    (value) => templateDispatch({ type: "setSelectedStickyCardTab", value }),
    [templateDispatch]
  );
  const [fieldsLoaded, setFieldsLoaded] = useState(false);
  const [profile] = useProfile();
  const canSaveTemplate = useCanSaveTemplate()
  useEffect(() => {
    Promise.all([
      xporterAPI
        .getTemplateFields()
        .then((response) => {
          if (response.map) {
            const data = profile.shop.shopify_plan.match("shopify_plus")
              ? response
              : response.filter(({ needsPlus }) => !needsPlus);
            templateDispatch({ type: "loadRawFields", value: data });
          } else {
            console.error("something borked before loadRawFields");
          }
        })
        .catch((response) => {
          console.error(response);
        }),
      xporterAPI
        .getTemplateQueries()
        .then((response) => {
          templateDispatch({ type: "loadRawQueries", value: response });
        })
        .catch((response) => {
          console.error(response);
        }),
    ]).finally(() => {
      setFieldsLoaded(true);
    });
  }, [fieldsLoaded, profile.shop.shopify_plan, templateDispatch, xporterAPI]);

  const [hasEnoughSpace, setHasEnoughSpace] = useState(false);

  const [observer] = useState(
    new ResizeObserver(([entry] = []) => {
      const width = entry?.borderBoxSize?.[0]?.inlineSize;
      setHasEnoughSpace(width > MIN_WIDTH_FOR_SIDE_BY_SIDE);
    })
  );

  const updateRef = useCallback(
    (el) => {
      if (el) {
        observer.observe(el);
      } else {
        observer.disconnect();
      }
    },
    [observer]
  );

  return (
    loaded && (
      <Stack vertical>
        {content.showError && content.error.length > 0 ? (
          <Banner
            status="critical"
            title={`To save this template, ${content.error.length} ${
              content.error.length > 1 ? "changes" : "change"
            } need to be made`}
          >
            <List type="bullet">
              {content.error.map((errorString) => (
                <List.Item key={errorString}>{errorString}</List.Item>
              ))}
            </List>
          </Banner>
        ) : null}
        {(content.unsavedChanges && !canSaveTemplate) ? (
          <Banner
            status="critical"
            title="You have made changes to this template that cannot be saved. See the upgrade notifications below for further information."
          />
        ) : null}
        <StarterTemplateBanner />
        {templateId && (
          <TemplateLimitBanner customBlurb="You are not able to save this template due to being at your limit. Please upgrade your plan or delete some templates." />
        )}
        {current.reportFields.length > 0 ? (
          <Card sectioned>
            <Stack vertical>
              <Stack vertical spacing="extraTight">
                <Heading>Report Layout</Heading>
                <p>
                  Manage the name and order of columns, create headers and footers, and configure aggregation.{" "}
                  <Button plain onClick={toggleAdvancedModalActive}>
                    View additional formatting options
                  </Button>
                </p>
              </Stack>
              <TemplateLayoutGrid />
            </Stack>
          </Card>
        ) : null}

        {Object.keys(current.reportPreSorting).length > 0 || debugVisible ? <TemplatePreSorting /> : null}

        <div className={`template-editor__field-area ${hasEnoughSpace ? "template-editor__field-area--wide" : ""}`} ref={updateRef}>
          <div className="template-editor__field-browser">
            <AddFieldCard showSettingsModal={toggleAdvancedModalActive} />
          </div>
          <div className="template-editor__active-fields">
            {hasEnoughSpace ? (
              <StickyCard
                header={
                  <>
                    <div className="Polaris-Card__Section" style={{ paddingBottom: 0 }}>
                      <Heading>Current Report Selections</Heading>
                    </div>
                    <Tabs tabs={STICK_CARD_TABS} selected={selectedStickyCardTab} onSelect={setSelectedStickyCardTab} />
                  </>
                }
                render={({ lockPosition, scrollToBottom }) => (
                  <Card.Section>
                    {selectedStickyCardTab === ACTIVE_FIELDS_TAB ? (
                      <ActiveFieldList lockPosition={lockPosition} scrollToBottom={scrollToBottom} />
                    ) : null}
                    {selectedStickyCardTab === ACTIVE_FILTERS_TAB ? <TemplateFiltering scrollToBottom={scrollToBottom} /> : null}
                    {selectedStickyCardTab === SORT_ORDER_TAB ? <TemplateSorting lockPosition={lockPosition} /> : null}
                  </Card.Section>
                )}
              />
            ) : (
              <Card>
                <Tabs tabs={STICK_CARD_TABS} selected={selectedStickyCardTab} onSelect={setSelectedStickyCardTab} />
                <Card.Section>
                  {selectedStickyCardTab === ACTIVE_FIELDS_TAB ? (
                    <ActiveFieldList lockPosition={null} scrollToBottom={null} />
                  ) : null}
                  {selectedStickyCardTab === ACTIVE_FILTERS_TAB ? <TemplateFiltering scrollToBottom={null} /> : null}
                  {selectedStickyCardTab === SORT_ORDER_TAB ? <TemplateSorting lockPosition={null} /> : null}
                </Card.Section>
              </Card>
            )}
          </div>
        </div>

        {current.reportFormat.type === "xml" && (
          <Card title="XML Node Names">
            <Xml />
          </Card>
        )}
      </Stack>
    )
  );
}

function PerlTemplate() {
  const [{ reportEval, reportSignature }, templateDispatch, content] = useReportTemplate();

  const profile = useProfile()[0];
  const setReportEval = (value) => templateDispatch({ type: "merge", value: { reportEval: value } });
  const setReportSignature = (value) => templateDispatch({ type: "merge", value: { reportSignature: value } });

  const csMember = profile.user.watchtower;
  const canSaveTemplate = useCanSaveTemplate()

  const codeSection = csMember ? (
    <>
      <Editor height="60rem" width="100%" defaultLanguage="perl" defaultValue={reportEval} onChange={setReportEval} />
      <TextField label="Signature" value={reportSignature} onChange={setReportSignature} />
    </>
  ) : (
    <Card>
      {(content.showError && content.error.length > 0) ? (
        <Banner
          status="critical"
          title={`To save this template, ${content.error.length} ${
            content.error.length > 1 ? "changes" : "change"
          } need to be made`}
        >
          <List type="bullet">
            {content.error.map((errorString) => (
              <List.Item key={errorString}>{errorString}</List.Item>
            ))}
          </List>
        </Banner>
      ) : null}
      {(content.unsavedChanges && !canSaveTemplate) ? (
        <Banner
          status="critical"
          title="You have made changes to this template that cannot be saved. See the upgrade notifications below for further information."
        />
      ) : null}
      <StarterTemplateBanner />
      <TemplateLimitBanner customBlurb="You are not able to save this template due to being at your limit. Please upgrade your plan or delete some templates." />
      <EmptyState fullWidth heading="This is a Custom Report">
        <p>This report can only be modified by our support team. Please reach out to us if you would like any modifications.</p>
      </EmptyState>
    </Card>
  );

  return (
    <Layout>
      <Layout.Section>{codeSection}</Layout.Section>
    </Layout>
  );
}

function TemplateEditorInContext() {
  const [profile] = useProfile();
  const xporterAPI = useXporterApi();
  const appRedirect = useRedirect();
  const { templateId } = useParams();
  const location = useLocation();
  const saveTemplate = useSaveTemplate();
  const runTemplateModal = useRunTemplate();

  const [current, templateDispatch, content] = useReportTemplate();
  const [loaded, setLoaded] = useState(false);
  const [banner, setBanner] = useState(null);
  const [error, setError] = useState(false);

  const debugClick = useDebugModeClick();
  const isDebugMode = useIsDebugMode();
  const [debug, setDebug] = useState(false);
  const toggleDebug = useCallback(() => setDebug(!debug), [debug]);

  const returnTo = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get("return_to") || "/template_library";
  }, [location.search]);

  const advancedModalActive = content.modals.settings;
  const toggleAdvancedModalActive = useCallback(
    () => templateDispatch({ type: "toggleModal", value: { settings: !advancedModalActive } }),
    [advancedModalActive, templateDispatch]
  );

  const [autoGenModalOpen, setAutoGenModalOpen] = useState(false);
  const toggleAutoGenModalOpen = useCallback(() => setAutoGenModalOpen(!autoGenModalOpen), [autoGenModalOpen]);

  const [baseDetailsModalOpen, setBaseDetailsModalOpen] = useState(false);

  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const toggleDuplicateModalOpen = useCallback(() => setDuplicateModalOpen(!duplicateModalOpen), [duplicateModalOpen]);
  const canSaveTemplate = useCanSaveTemplate()

  useEffect(() => {
    if (loaded) {
      const state = !current.name || (current.reportEval ? false : !current.mainItem);
      setBaseDetailsModalOpen(state);
    }
  }, [current.mainItem, current.name, current.reportEval, loaded]);

  const toggleBaseDetailsModalOpen = useCallback(
    ({ redirect }) => {
      if (redirect) {
        appRedirect(returnTo);
      }
      setBaseDetailsModalOpen(!baseDetailsModalOpen);
    },
    [appRedirect, baseDetailsModalOpen, returnTo]
  );

  const [hasReportEval, setHasReportEval] = useState(
    (Object.prototype.hasOwnProperty.call(current, "reportEval") && current.reportEval) ||
      new URLSearchParams(location.search).has("code")
  );

  const setTemplate = useCallback(
    (templateData, id) => templateDispatch({ type: "load", value: { template: templateData, id, profile } }),
    [profile, templateDispatch]
  );

  // modal nonsense
  useEffect(() => {
    if (templateId) {
      xporterAPI
        .getTemplate(templateId)
        .then((response) => {
          if (response?.error) {
            console.error(response.error);
            setBanner(response.error);
            setError(true);
          } else {
            setHasReportEval(Object.prototype.hasOwnProperty.call(response, "report_eval") && response.report_eval);
            setTemplate(response, templateId);
          }
        })
        .catch((response) => {
          console.error(response);
          setError(true);
        })
        .finally(() => {
          setLoaded(true);
        });
    } else {
      setLoaded(true);
    }
  }, [setTemplate, templateId, xporterAPI]);

  useEffect(() => {
    window.xporterLoadTemplate = (templateJson) => {
      switch (typeof templateJson) {
        case "string":
          templateDispatch({ type: "merge", value: JSON.parse(templateJson) });
          break;
        default:
          templateDispatch({ type: "merge", value: templateJson });
          break;
      }
    };

    window.xporterGetTemplate = () => current;

    window.xporterDeleteTemplate = async (id = null) => {
      if (id !== templateId) {
        console.error("id provided and id of template don't match ");
        console.error("Template Deletion Failed");
        return;
      }

      xporterAPI
        .deleteTemplate(templateId)
        .then(() => {
          appRedirect("/template_library");
        })
        .catch((payload) => {
          templateDispatch({ type: "appendErrors", value: [payload.error] });
          console.error("Error Deleting template", payload);
        });
    };

    window.enableCustomReporting = () => {
      setHasReportEval(true);
    };

    return () => {
      delete window.xporterloadTemplate;
      delete window.xporterGetTemplate;
      delete window.xporterDeleteTemplate;
      delete window.enableCustomReporting;
    };
  });

  const saveBarSaveAction = useCallback(async () => {
    if (content.unsavedChanges && canSaveTemplate) {
      saveTemplate();
    } else {
      const templates = await xporterAPI.getAllTemplates();
      runTemplateModal({ template: templates.find(({ ID }) => ID === Number(templateId)) });
    }
  }, [canSaveTemplate, content.unsavedChanges, runTemplateModal, saveTemplate, templateId, xporterAPI])

  const templateParams = {
    loaded,
    templateId,
    returnTo,
    debugVisible: isDebugMode,
    toggleAutoGenModalOpen,
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  const editor = hasReportEval ? <PerlTemplate {...templateParams} /> : <NormalTemplate {...templateParams} />;

  const skeleton = (
    <SkeletonPage primaryAction fullWidth breadcrumbs>
      <Card sectioned>
        <SkeletonBodyText lines={15} />
      </Card>
    </SkeletonPage>
  );

  const debugModal = (
    <Modal
      open={debug}
      onClose={toggleDebug}
      title="Debug"
      fullWidth
      primaryAction={{
        content: "Close",
        onAction: toggleDebug,
      }}
    >
      <Modal.Section>
        <ReactJson collapsed src={content} />
      </Modal.Section>
    </Modal>
  );

  const actions = [
    {
      content: "Formatting Options",
      id: "pageAction-settings",
      onAction: toggleAdvancedModalActive,
    },
    !hasReportEval && {
      content: "Duplicate",
      id: "pageAction-duplicate",
      onAction: toggleDuplicateModalOpen,
    },
    isDebugMode && {
      content: "Debug",
      id: "pageAction-debug",
      onAction: toggleDebug,
    },
  ].filter(Boolean);

  const fileFormatName = useMemo(
    () => FILE_FORMATS.find((format) => format.value === current?.reportFormat?.type)?.label || "Unknown",
    [current?.reportFormat?.type]
  );

  if (banner || error) {
    return (
      <Page fullWidth title="Error" breadcrumbs={[{ content: "Overview", url: "/" }]}>
        <Banner title="Error Loading Template" status="critical">
          {!!banner && <p>{banner}</p>}
        </Banner>
      </Page>
    );
  }

  return loaded ? (
    <Page
      fullWidth
      title={`${current?.name}\u00a0` || "Untitled Template"}
      titleMetadata={
        <Stack spacing="extraTight">
          <Button
            plain
            color="subdued"
            icon={<FormatIcon format={current?.reportFormat?.type} />}
            onClick={toggleBaseDetailsModalOpen}
            label={fileFormatName}
          />
          <Button plain color="subdued" icon={EditMinor} onClick={toggleBaseDetailsModalOpen} />
        </Stack>
      }
      breadcrumbs={[{ content: "Overview", url: returnTo }]}
      primaryAction={{
        content: content.unsavedChanges && canSaveTemplate ? "Save & Run" : "Run",
        id: "pageAction-runsave",
        primary: true,
        onAction: async () => {
          if (content.unsavedChanges && canSaveTemplate) {
            saveTemplate({ runNow: true });
          } else {
            const templates = await xporterAPI.getAllTemplates();
            runTemplateModal({ template: templates.find(({ ID }) => ID === Number(templateId)) });
          }
        },
      }}
      secondaryActions={actions}
    >
      <Prompt when={content.unsavedChanges} message="You have unsaved changes, are you sure you want to leave?" />
      <div
        style={{
          paddingLeft: "5rem",
          margin: "0 0 2rem",
        }}
      >
        {current.description ? (
          <TextStyle variation="subdued">
            <TextStyle variation="strong">Description:</TextStyle>
            <br />
            {current.description}
          </TextStyle>
        ) : null}
        <div
          style={{
            padding: "1rem",
          }}
        >
          <AutoGenLine openAutoGenModal={toggleAutoGenModalOpen} />
        </div>
      </div>
      {editor}
      <FooterHelp>
        Xporter -{" "}
        <Button plain monochrome removeUnderline onClick={debugClick}>
          © {new Date().getYear() + 1900}
        </Button>{" "}
        <Link url="https://moddapps.com" href="https://moddapps.com">
          Modd Apps
        </Link>
      </FooterHelp>
      <TemplateSettingsModal open={advancedModalActive} toggleOpen={toggleAdvancedModalActive} />
      <AutoGenModal open={autoGenModalOpen} toggleOpen={toggleAutoGenModalOpen} />
      <TemplateBaseDetailsModal open={baseDetailsModalOpen} toggleOpen={toggleBaseDetailsModalOpen} />
      <DuplicateModal open={duplicateModalOpen} toggleOpen={toggleDuplicateModalOpen} />
      {canSaveTemplate ? <TemplateSaveBar saveAction={saveBarSaveAction} /> : null}
      {debugModal}
    </Page>
  ) : (
    skeleton
  );
}

function TemplateEditor() {
  return (
    <TemplateContext>
      <TemplateEditorInContext />
    </TemplateContext>
  );
}

export default TemplateEditor;
